.dashboard-container {
  //background-image: linear-gradient(to bottom, #FED5FF , #B8EAFF, #B3E8FF);
  height: calc(100% - 100px);
  .left {

  }

  .right {
    .main-dashboard {
      border-radius: 20px;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

      .sindeso-ai {
        .left-layout {
          height: 100%;
          border-right: 1px solid #D1D5DB;
          padding: 25px 20px;
          .item-container {
            border: 1px solid #D1D5DB;
            color: #767676;
            font-size: 12px;
            border-radius: 8px;
            padding: 8px 16px;
            cursor: pointer;
            .icon {
              min-width: 16px;
              margin-right: 8px;
            }
          }
          .item-active {
            border: 1px solid #9649F8;
          }
        }
        .right-layout {

        }
      }
    }
  }
}