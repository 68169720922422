.admin-background {
  background: #14141C;
  height: 100vh;

  .login-form {
    height: 420px;
    width: 500px;
    background: #1C1E2C;
    padding: 40px;

    .form-login {
      text-align: left;
      color: white;

      input {
        width: 420px;
        height: 42px;
        background: #333440;
        border-radius: 3px;
        border: 1px #3E4760;
        margin-top: 8px;
        color: #FFFFFF;
        padding: 8px;
      }

      .btn-login-admin {
        width: 100%;
        background: #7B8FF0;
        color: #FFFFFF;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 42px;
        margin-top: 50px;
      }
    }
  }
}

.user-list-container {
  background: #14141C;
  min-height: 100vh;
  padding-top: 65px;

  .header-admin {
    position: fixed;
    top: 0;
    width: 100%;
    height: 65px;
    background: #1D222E;
  }

  .navbar-container {
    .navbar-item {
      color: white;
      padding: 8px;
      border-radius: 3px;
      font-size: 16px;
    }
    .selected-nav {
      background: #272D3D;
      color: #7B8FF0;
    }
  }

  .admin-user-container {
    .title {
      font-size: 18px;
      color: white;
      font-weight: bold;
      text-align: left;
      margin-bottom: 24px;
    }

    .filter-group {
      .right {
        button {
          background: #7B8FF0;
          border-radius: 3px;
          border: none;
          height: 42px;
          width: 113px;
        }
      }
      .left {
        .status {
          color: white;
          text-align: left;
          select {
            width: 110px;
            height: 42px;
            color: white;
            border-radius: 3px;
            background: #1D222E;
            border-color: #3E4760;
            padding: 0 16px;
            margin-top: 8px;
          }
          input {
            width: 250px;
            height: 42px;
            border-radius: 3px;
            background: transparent;
            border: 1px solid #3E4760;
            padding: 0 16px;
            margin-top: 8px;
            color: #FFFFFF;
          }
        }
      }
    }
  }
}